import { logoUrl } from "@/api/image/api";
import { useMemo } from "react";

export default function LogoTitle({
  className = "",
  isTop = false,
}: {
  className?: string;
  isTop?: "topMain" | "topSub" | false;
}) {
  const theme = localStorage.getItem("hs_theme");

  const isDark = useMemo(() => {
    if (isTop === "topMain") {
      return true;
    }
    if (isTop === "topSub") {
      return false;
    }
    return theme === "dark";
  }, [isTop, theme]);

  return (
    <a
      href={route("top")}
      className={
        "flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0 " +
        className
      }
    >
      <img className="w-full h-[60px] " src={logoUrl(isDark)} />
    </a>
  );
}

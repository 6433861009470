const baseUrl = `${import.meta.env.VITE_PUSHER_HOST}/image`;

export function wordCloudImageUrl(humanId: string) {
  return `${baseUrl}/wordCloud/${humanId}.png`;
}

export function topKeyVisualUrl() {
  return `${baseUrl}/general/top_kv.png`;
}

export function wordCloudLatestMeetingImageUrl() {
  return `${baseUrl}/wordCloud/latest_meeting.png`;
}

export function logoUrl(isDark?: boolean) {
  const fileName = isDark ? "Logo_Y_E_White_L" : "Logo_Y_E_Black_L";
  return `${baseUrl}/general/${fileName}.png`;
}
